import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAkGYZGZ8UbN_JYaYP5lZWSBHq5gDU3S1c",
  authDomain: "minimalnetwork-prod.firebaseapp.com",
  projectId: "minimalnetwork-prod",
  storageBucket: "minimalnetwork-prod.appspot.com",
  messagingSenderId: "748769222928",
  appId: "1:748769222928:web:9349a512a515b5a5cac595",
};
const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();
export default app;
